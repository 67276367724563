(function ($, undefined) {
  /*============================================
    Header thin on scroll
  ==============================================*/
  $(function () {
    const $document = $(document),
      $header = $(".header"),
      HEADER_TOP_OFFSET = 20;

    const changeHeaderColors = () => {
      if ($document.scrollTop() > HEADER_TOP_OFFSET) {
        $header.addClass("header_thin");
      } else {
        $header.removeClass("header_thin");
      }
    }

    $document.scroll(changeHeaderColors);
    changeHeaderColors();
  });

  /*============================================
  Make body static when header menu is open (mob)
  ==============================================*/
  $(function () {
    const $navMenu = $('#navbarSupportedContent'),
      $body = $('body');

    $navMenu.on('shown.bs.collapse', () => $body.css({'overflow-y': 'hidden'}));
    $navMenu.on('hidden.bs.collapse', () => $body.css({'overflow-y': 'visible'}));
  });

  /*============================================
  Swiper Slides
  ==============================================*/
  new Swiper(".partners-swiper", {
    spaceBetween: 25,
    slidesPerView: 1,
    speed: 2500,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    breakpoints: {
      575: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 4,
      },
      1200: {
        spaceBetween: 65,
        slidesPerView: 5
      }
    }
  });

  new Swiper(".sources-icons-swiper-left", {
    spaceBetween: 10,
    slidesPerView: 4,
    speed: 2500,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    breakpoints: {
      575: {
        spaceBetween: 15,
        slidesPerView: 6,
      },
      768: {
        slidesPerView: 8
      },
      1200: {
        slidesPerView: 10
      }
    }
  });

  new Swiper(".sources-icons-swiper-right", {
    spaceBetween: 10,
    slidesPerView: 4,
    speed: 2500,
    loop: true,
    allowTouchMove: false,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true
    },
    breakpoints: {
      575: {
        spaceBetween: 15,
        slidesPerView: 6,
      },
      768: {
        slidesPerView: 8
      },
      1200: {
        slidesPerView: 10
      }
    }
  });

  /*============================================
   Removing loading attributes in old Safari browsers
   ==============================================*/
  navigator.browserSpecs = (function () {
    let ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return {name: tem[1].replace('OPR', 'Opera'), version: tem[2]};
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null)
      M.splice(1, 1, tem[1]);
    return {name: M[0], version: M[1]};
  })();

  if (navigator.browserSpecs.name === 'Safari') {
    if (Number(navigator.browserSpecs.version) < 16.4) {
      $('img').each(function () {
        const $this = $(this);
        const loadingAttr = $this.attr('loading');
        if (typeof loadingAttr !== typeof undefined && loadingAttr !== false) {
          $this.removeAttr('loading');
        }
      });
    }
  }

  /*============================================
  Background moving on mouse move
  ==============================================*/
  $(function () {
    if (window.innerWidth >= 991) {
      const $parallax = $(".parallax"),
        $parallaxImage = $(".js-parallax-image");

      if ($parallaxImage.length) {
        $parallax.on('mousemove', function (e) {
          const $this = $(this),
            parallaxWidth = $this.width(),
            parallaxHeight = $this.height(),
            offsetX = 0.5 - e.pageX / parallaxWidth,
            offsetY = 0.5 - e.pageY / parallaxHeight,
            imageOffset = 50;

          $parallaxImage.css('transform', `translateX(${offsetX * imageOffset}px)`);
        });
      }
    }
  });
})(jQuery);
